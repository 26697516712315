import Vue from 'vue';
/**
 * @typedef {import('./state').contactState} contactState
 */
import state from './state';
/** @type {import('axios').AxiosInstance} */
const http = Vue.$http;
export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get contacts state
     * @param {contactState} state
     * @return {contactState['contacts']}
     */
    getContacts(state) {
      return state.contacts;
    },
    /**
     * get create contact data
     * @param {contactState} state
     * @return {contactState['createContactData']}
     */
    getCreateContactData(state) {
      return state.createContactData;
    },
    /**
     * get selected contact data
     * @param {contactState} state
     * @return {contactState['selectedContact']}
     */
    getSelectedContactData(state) {
      return state.selectedContact;
    },
    /**
     * get self contacts state
     * @param {contactState} state
     * @return {contactState['selfContacts']}
     */
    getSelfContacts(state) {
      return state.selfContacts;
    },
    /**
     * get self cards state
     * @param {cardState} state
     * @return {cardState['selfCards']}
     */
    getSelfCards(state) {
      return state.selfCards;
    }
  },
  mutations: {
    /**
     * set contacts state
     * @param {contactState} state
     * @param {contactState['contacts']} payload
     */
    setContacts(state, payload) {
      state.contacts = payload;
    },
    /**
     * update create contact data
     * @param {contactState} state
     * @param {contactState['createContactData']} payload
     */
    updateCreateContactData(state, payload) {
      state.createContactData = payload;
    },
    /**
     * update selected contact data
     * @param {contactState} state
     * @param {contactState['createContactData']} payload
     */
    updateSelectedContactData(state, payload) {
      state.selectedContact = payload;
    },
    /**
     * update self contacts
     * @param {contactState} state
     * @param {contactState['selfContacts']} payload
     */
    updateSelfContacts(state, payload) {
      state.selfContacts = payload;
    },
    /**
     * update self cards
     * @param {cardState} state
     * @param {cardState['selfCards']} payload
     */
    updateSelfCards(state, payload) {
      state.selfCards = payload;
    },
    /**
     * set selected contact data
     * @param {contactState} state
     * @param {contactState['selectedContact'] | null} payload
     */
    setSelectedContact(state, payload) {
      state.selectedContact = payload;
    },
  },
  actions: {
    /**
     * list all contacts
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} accountId
     * @returns {Promise<contactState['contacts']>}
     */
    listAllContacts({ commit, state }, {accountId, limit = 25, offset = 0, query}) {
      let url = `v1/contact?accountId=${accountId}&type=others&limit=${limit}&offset=${offset}`;
      if(query) {
        url = `${url}&query=${query}`;
      }
      return new Promise((resolve, reject) => {
        http
          .get(url)
          .then(({ data }) => {
            let d = {};
            if(offset === 0) {
              d = data;
            } else {
              const contacts = {...state.contacts};
              d = {
                data: [...contacts.data,...data.data],
                total: data.total
              }
            }
            commit('setContacts', d);
            resolve(d);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * create contact
     * @returns {Promise<contactState['createContactData']>}
     */
    createContact(_, payload) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/contact', payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * get contact details
     * @param {*} _
     * @param {string} contactId
     */
    getContactDetails(_, contactId) {
      return new Promise((resolve, reject) => {
        http
          .get(`v1/contact/${contactId}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * update contact details
     * @param {*} _
     * @param {Object} payload
     */
    updateContact(_, payload) {
      return new Promise((resolve, reject) => {
        const contactId = payload.id;
        delete payload.id;
        http
          .patch(`v1/contact/${contactId}`, payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * archive contact
     * @param {*} _
     * @param {string} contactId
     */
    archiveContact(_, contactId) {
      return new Promise((resolve, reject) => {
        http
          .delete(`v1/contact/${contactId}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * list self contacts
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} accountId
     * @returns {Promise<contactState['contacts']>}
     */
    listSelfContacts({ commit }, accountId) {
      return new Promise((resolve, reject) => {
        http
          .get(`/v1/contact?accountId=${accountId}&type=self`)
          .then(({ data }) => {
            commit('updateSelfContacts', data.data);
            resolve(data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * list self contacts
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} accountId
     * @param {number} limit
     * @param {number} offset
     * @param {string} query
     * @returns {Promise<cardState['selfCards']>}
     */
    listSelfCards({ commit }, {accountId, limit = 20, offset = 0, query = ''}) {
      let url = `v1/contact?accountId=${accountId}&type=others&limit=${limit}&offset=${offset}`;
      if(query && query.length !== 0) {
        url = `${url}&query=${query}`;
      }
      return new Promise((resolve, reject) => {
        http
          .get(url)
          .then(({ data }) => {
            const selfCards = data.data.filter( card => card.debitCard);
            const d = {
              data: selfCards,
              total: selfCards.length
            }
            commit('updateSelfCards', d);
            resolve(d);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * @param contactId 
     * @returns 
     */
    getLinkCardToken(_, contactId) {
      return new Promise((resolve, reject) => {
        http(`v1/contact/${contactId}/debitcard/token`, {
          method: 'POST',
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    }
  }
};
