import Vue from 'vue';
import axios from 'axios';
/**
 * @typedef {import('./state').kybState} kybState
 */
import state from './state';
/** @type {import('axios').AxiosInstance} */
const http = Vue.$http;

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get business list from store
     * @param {kybState} state
     * @returns {kybState['bussinessDetails']}
     */
    getBusinessList(state) {
      return state.bussinessDetails;
    },
    /**
     * get business details fetched status
     * @param {kybState} state
     * @returns {kybState['bussinessDetailsFetchedStatus']}
     */
    getBussinessDetailsFetchedStatus(state) {
      return state.bussinessDetailsFetchedStatus;
    },
    /**
     * get business details fetched status
     * @param {kybState} state
     * @returns {kybState['selectedBusinessType']}
     */
    getSelectedBusiness(state) {
      return state.selectedBusinessType;
    },
    /**
     * get kyb details
     * @param {kybState} state
     * @returns {kybState['kybDetails']}
     */
    getKybDetails(state) {
      return state.kybDetails;
    },
    /**
     * get disclosureStatus url and status
     * @param {kybState} state
     * @returns {kybState['agreementUrlAndStatus']}
     */
    getAgreementUrlAndStatus(state) {
      return state.agreementUrlAndStatus;
    },
    /**
     * get owners details
     * @param {kybState} state
     * @returns {kybState['owners']}
     */
    getOwnersDetails(state) {
      return state.owners;
    },
    /**
     * get create page owner details
     * @param {kybState} state
     * @returns {kybState['createPageOwnerDetails']}
     */
    getCreatePageOwnersDetail(state) {
      return state.createPageOwnerDetails;
    },
    getNacisCodeData(state) {
      return state.nacisCodeData;
    },
    getProjectionData(state) {
      return state.projectionData;
    },
    /**
     * get ownershipDisclosure data
     * @param {kybState} state 
     * @returns {kybState['ownershipDisclosure']}
     */
    getOwnershipDisclosure(state) {
      return state.ownershipDisclosure;
    },
    /**
     * get documents list from store
     * @param {kybState} state
     * @returns {kybState['documents']}
     */
    getDocumentsList(state) {
      return state.documents;
    },
    /**
     * get create page document details
     * @param {kybState} state
     * @returns {kybState['createPageDocumentDetails']}
     */
    getCreatePageDocumentDetails(state) {
      return state.createPageDocumentDetails;
    },
  },
  mutations: {
    /**
     * set business list
     * @param {kybState} state
     * @param {kybState['bussinessDetails']} payload
     */
    setBusinessList(state, payload) {
      state.bussinessDetails = payload;
      state.bussinessDetailsFetchedStatus = 'fetched';
    },
    /**
     * set selected business
     * @param {kybState} state
     * @param {kybState['selectedBusinessType']} payload
     */
    setSelectedBusiness(state, payload) {
      state.selectedBusinessType = payload;
    },
    /**
     * set kyb details
     * @param {kybState} state
     * @param {kybState['kybDetails']} payload
     */
    setKybDetails(state, payload) {
      state.kybDetails = payload;
    },
    /**
     * set disclosureStatus url and status
     * @param {kybState} state
     * @param {kybState['agreementUrlAndStatus']} payload
     */
    setAgreementUrlAndStatus(state, payload) {
      state.agreementUrlAndStatus = payload;
    },
    /**
     * set owners
     * @param {kybState} state
     * @param {kybState['owners']} payload
     */
    setOwnersDetails(state, payload) {
      state.owners = payload;
    },
    /**
     * set create page owner details
     * @param {kybState} state
     * @param {kybState['createPageOwnerDetails']} payload
     */
    setCreatePageOwnerDetails(state, payload) {
      state.createPageOwnerDetails = payload;
    },
    /**
     * set nacisCode data
     * @param {kybState} state
     * @param {kybState['createPageOwnerDetails']} payload
     */
    setNacisCodeData(state, payload) {
      state.nacisCodeData = payload
    },
    /**
     * set projection data
     * @param {kybState} state
     * @param {kybState['createPageOwnerDetails']} payload
     */
    setProjectionData(state, payload) {
      state.projectionData = payload
    },
    /**
     * set ownershipDisclosure
     * @param {kybState} state
     * @param {kybState['ownershipDisclosure']} payload
     */
    setownershipDisclosure(state, payload) {
      state.ownershipDisclosure = payload
    },
    /**
     * set business list
     * @param {kybState} state
     * @param {kybState['bussinessDetails']} payload
     */
    setDocumentsList(state, payload) {
      state.documents = payload;
    },
    /**
     * update business list
     * @param {kybState} state
     * @param {kybState['dicument']} payload
     */
    updateDocumentsList(state, payload) {
      console.log('===> UPDATE DOCUMENTS LIST', payload, state.documents)
      state.documents = state.documents.map((_document) => _document.key === payload.key ? payload : _document)
    },
    /**
     * set create page owner details
     * @param {kybState} state
     * @param {kybState['createPageDocumentDetails']} payload
     */
    setCreatePageDocumentDetails(state, payload) {
      state.createPageDocumentDetails = payload;
    }
  },
  actions: {
    /**
     * fetch all business list
     * @param {Object} param0
     * @param {Function} param0.commit
     */
    listAllBusiness({ commit }) {
      return new Promise((resolve, reject) => {
        http
          .get('v1/business')
          .then(({ data }) => {
            commit('setBusinessList', data.data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * create new business
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {*} payload
     */
    createBusiness({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/business', payload)
          .then(({ data }) => {
            commit('setSelectedBusiness', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * update business
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} payload
     * @param {businessId} payload.businessId
     * @param {*} payload.business
     */
    updateBusiness({ commit }, { businessId, business }) {
      return new Promise((resolve, reject) => {
        http
          .patch('v1/business/' + businessId, business)
          .then(({ data }) => {
            commit('setSelectedBusiness', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * fetch business
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     */
    fetchBusiness(_, businessId) {
      return new Promise((resolve, reject) => {
        http
          .get('v1/business/' + businessId)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    fetchNaicsCodeData({ commit }) {
      return new Promise((resolve, reject) => {
        http
          .get('v1/business/naicscode')
          .then(({ data }) => {
            commit('setNacisCodeData', data.data)
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    getProjection({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        http
          .get(`/v1/business/${businessId}/projection`)
          .then(({ data }) => {
            commit('setProjectionData', data.data)
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    updateProjection({ commit }, { businessId, payload }) {
      return new Promise((resolve, reject) => {
        http
          .patch(`/v1/business/${businessId}/projection`, payload)
          .then(({ data }) => {
            commit('setProjectionData', data.data)
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * fetch
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     */
    fetchKybDetails({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        http
          .get('v1/business/' + businessId + '/kyb')
          .then(({ data }) => {
            commit('setKybDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * submit kyb
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     */
    submitKyb({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/business/' + businessId + '/kyb', { businessId })
          .then(({ data }) => {
            commit('setKybDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * list all owners
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     */
    fetchAllOwners({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        http
          .get(`v1/member?businessId=${businessId}`)
          .then(({ data }) => {
            commit('setOwnersDetails', data.data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * create owner
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} payload
     */
    createOwner(_, payload) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/member', payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * update owner
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} payload
     */
    updateOwner(_, { ownerId, details }) {
      return new Promise((resolve, reject) => {
        http
          .patch(`v1/member/${ownerId}`, details)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * submit owner kyc
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} ownerId
     */
    submitOwnerKyc(_, ownerId) {
      return new Promise((resolve, reject) => {
        http
          .post(`v1/member/${ownerId}/kyc`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
 * list all documents
 * @param {Object} param0
 * @param {Function} param0.commit
 * @param {string} businessId
 */
    fetchAllDocuments({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        http
          .get(`v1/business/${businessId}/document/`)
          .then(({ data }) => {
            commit('setDocumentsList', data.data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * create document
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     * @param {Object} payload
     */
    createDocument(_, { businessId, payload }) {
      return new Promise((resolve, reject) => {
        http
          .post(`v1/business/${businessId}/document`, payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * upload document
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} payload
     */
    uploadDocument(_, { url, file, contentType }) {
      return new Promise((resolve, reject) => {
        axios
          .put('', file, { headers: { 'Content-Type': contentType }, baseURL: url })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * delete document
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     * @param {string} documentId
     */
    deleteDocument(_, { businessId, documentId }) {
      return new Promise((resolve, reject) => {
        http
          .delete(`v1/business/${businessId}/document/${documentId}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * get ownershipDisclosure details
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     */
    getOwnershipDisclosure({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        http
          .get(`/v1/business/${businessId}/ownershipDisclosure`)
          .then(({ data }) => {
            commit('setownershipDisclosure', data.data)
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * get ownershipDisclosure details
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} businessId
     */
    updateOwnershipDisclosure({ commit }, { businessId, payload }) {
      return new Promise((resolve, reject) => {
        http
          .post(`/v1/business/${businessId}/ownershipDisclosure`, payload)
          .then(({ data }) => {
            commit('setProjectionData', data.data)
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    }
  }
};
