/**
 * @typedef {import('./state').dashbaordState} dashbaordState
 */
import state from './state';

export default {
  namespaced: true,
  state,
  getters: {},
  mutations: {},
  actions: {}
};
