<template>
  <div id="loader" class="init-loader">
    <BrandImg
      class="init-loader__logo"
      src="logos/loading-icon.svg"
      alt="Logo" />
    <p class="init-loader__text">
      {{ statusText }}
    </p>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import BrandImg from '@/components/BrandImg.vue';
import loginMixins from '../mixins/login';
import Vue from 'vue';
import auth0 from '@/plugins/auth0';
import { setEnv } from '@/utils/env';

export default {
  mixins: [loginMixins],
  data() {
    return {
      statusText: 'Verifying details...'
    }
  },
  created() {
    const env =  window.sessionStorage.getItem('selectedEnv')
    setEnv(env);
    (async () => {
      try {
        Vue.use(auth0);
        await Vue.getAuth0Instance()
      
        await this.handleRedirectCallback();
      
        const {accessToken} = await this.checkSession();
        this.verify(accessToken, undefined, (statusText) => {
          this.statusText = statusText;
        }).catch((e) => {
          console.log('e',e)
          this.apiErrorMessage(e.errorDescription || e.description || e.error || 'Something went wrong!!');
          this.$router.push('/welcome');
        });
      } catch(e) {
        console.log(e)
        this.$router.push('/welcome');
      }
    })()
  },
  methods: {
    ...mapActions('user', ['checkSession', 'handleRedirectCallback']),
  },
  components: { BrandImg }
}
</script>