import Fund from './index.vue';

const routes = [
  {
    path: '/fund',
    component: Fund,
    children: [
      {
        path: '',
        name: 'FundDetails',
        component: () => import('./views/Fund.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'pull-funds',
        name: 'PullFunds',
        component: () => import('./views/PullFunds.vue'),
        meta: {
          requiresAuth: false,
          preventBrowserBack: true
        }
      },
      {
        path: 'pull-funds/amount',
        name: 'PullFundsAmount',
        component: () => import('./views/PullFundsAmount.vue'),
        meta: {
          requiresAuth: false,
          preventBrowserBack: true
        }
      },
      {
        path: 'pull-funds/success',
        name: 'PullFundsSuccess',
        component: () => import('./views/PullFundsSuccess.vue'),
        meta: {
          requiresAuth: false,
          preventBrowserBack: true
        }
      },
      {
        path: 'pull-from-cards',
        name: 'PullFromCard',
        component: () => import('./views/PullFromCard.vue'),
        meta: {
          requiresAuth: false,
          preventBrowserBack: true
        }
      },
      {
        path: 'pull-from-cards/link-card',
        name: 'LinkCard',
        component: () => import('./views/LinkCard.vue'),
        meta: {
          requiresAuth: false,
          preventBrowserBack: true
        }
      },
      {
        path: 'move-funds-out',
        name: 'MoveFundsOut',
        component: () => import('./views/MoveFundsOut.vue'),
        meta: {
          requiresAuth: false,
          preventBrowserBack: true
        }
      },
      {
        path: 'move-funds-out/amount',
        name: 'MoveFundsOutAmount',
        component: () => import('./views/MoveFundsOutAmount.vue'),
        meta: {
          requiresAuth: false,
          preventBrowserBack: true
        }
      },
      {
        path: 'move-funds-out/success',
        name: 'MoveFundsOutSuccess',
        component: () => import('./views/MoveFundsOutSuccess.vue'),
        meta: {
          requiresAuth: false,
          preventBrowserBack: true
        }
      },
      {
        path: 'move-funds-out/intrabank',
        name: 'MoveFundsOutIntrabank',
        component: () => import('./views/IntrabankFundOut.vue'),
        meta: {
          requiresAuth: false,
          preventBrowserBack: true
        }
      }
    ]
  }
];

export default routes;
