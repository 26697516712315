import Vue from 'vue';
/**
 * @typedef {import('./state').accountState} accountState
 */
import state from './state';
/** @type {import('axios').AxiosInstance} */
const http = Vue.$http;

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get all account
     * @param {accountState} state
     * @returns {accountState['accounts']}
     */
    getAllAccount(state) {
      return state.accounts;
    },
    /**
     * get selected account
     * @param {accountState} state
     */
    getSelectedAccount(state) {
      return state.selectedAccount;
    },
    /**
     * get selected account - Type
     * @param {accountState} state
     */
    isBusinessAccountType(state) {
      return state.selectedAccount.type === 'businessChecking';
    },
    /**
     * get create account data
     * @param {accountState} state
     */
    getCreateAccountData(state) {
      return state.createAccountData;
    },
    /**
     * get create account data
     * @param {accountState} state
     */
    getStatements(state) {
      return state.statements;
    },
    /**
     * get grouped account (Business or Personal grouping)
     * @param {accountState} state
     * @returns {accountState['groupedAccounts']}
     */
    getGroupedAccounts(state) {
      return state.groupedAccounts;
    },
    /**
     * get add account back page
     * @param {accountState} state
     * @returns {accountState['addAccountBackPage']}
     */
    getAddAccountBackPage(state) {
      return state.addAccountBackPage;
    },
    /**
     * get selected account type
     * @param {accountState} state
     * @returns {accountState['getSelectedAccountType']}
     */
    getSelectedAccountType(state) {
      return state.selectedAccountType;
    },
  },
  mutations: {
    /**
     * set accounts details
     * @param {accountState} state
     * @param {accountState['accounts']} payload
     */
    setAccounts(state, payload) {
      state.accounts = payload;
    },
    /**
     * set selected accounts
     * @param {accountState} state
     * @param {accountState['selectedAccount']} payload
     */
    setSelectedAccount(state, payload) {
      state.selectedAccount = payload;
    },
    /**
     * update create account data
     * @param {accountState} state
     * @param {accountState['createAccountData']} payload
     */
    updateCreateAccountData(state, payload) {
      state.createAccountData = payload;
    },
    /**
     * update account statements
     * @param {accountState} state
     * @param {accountState['statements']} payload
     */
    updateStatements(state, payload) {
      return (state.statements = payload);
    },
    /**
     * set grouped accounts by Business/Personal
     * @param {accountState} state
     * @param {accountState['groupedAccounts']} payload
     */
    setGroupedAccounts(state, payload) {
      state.groupedAccounts = payload;
    },
    /**
     *
     * @param {accountState} state
     * @param {accountState['addAccountBackPage']} payload
     */
    setAddAccountBackPage(state, payload) {
      state.addAccountBackPage = payload;
    },
    /**
     * set selected account type
     * @param {accountState} state
     * @param {accountState['selectedAccountType']} payload
     */
    setSelectedAccountType(state, payload) {
      state.selectedAccountType = payload;
    }
  },
  actions: {
    /**
     * list all accounts
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     * @returns {Promise<accountState['accounts']>}
     */
    listAllAccounts({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        let url = 'v1/account?limit=100';
        if(businessId) {
          url += `&businessId=${businessId}`;
        }
        http
          .get(url)
          .then(({ data }) => {
            const accounts = data.data.map((a) => {
              a.availableBalance =
                a.availableBalance.length === 0 ? '0.00' : a.availableBalance;
              return a;
            });

            commit('setAccounts', accounts);
            resolve(accounts);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * create account
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {any} payload
     * @returns {Promise<accountState['accounts']>}
     */
    createAccount({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/account', payload)
          .then(({ data }) => {
            commit('setSelectedAccount', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * get account details
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} accountId
     */
    getAccount({ commit }, accountId) {
      return new Promise((resolve, reject) => {
        http
          .get(`v1/account/${accountId}`)
          .then(({ data }) => {
            commit('setSelectedAccount', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} accountId
     */
    fetchStatements({ commit }, accountId) {
      return new Promise((resolve, reject) => {
        http
          .get(`v1/account/${accountId}/statement`)
          .then(({ data }) => {
            commit('updateStatements', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * get statement by month
     * @param {Function} param0.commit
     * @param {Object} param1
     * @param {string} param1.accountId
     * @param {string} param1.month
     * @param {string} param1.year
     * @param {string} param1.export
     */
    getStatementByMonth(_, { year, month, accountId, format }) {
      return new Promise((resolve, reject) => {
        let e = format ? format : 'json';
        http(`v1/account/${accountId}/statement/${year}/${month}?export=${e}`, {
          method: 'GET',
          responseType: format === 'pdf' ? 'blob' : 'json'
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    }
  }
};
