import Vue from 'vue';
import axios from 'axios';
import { getEnv } from '@/utils/env';
/**
 * @typedef {import('./state').transactionState} transactionState
 */
import state from './state';
/** @type {import('axios').AxiosInstance} */
const http = Vue.$http;
const multikrdApi = getEnv('VUE_APP_API_MULTIKRD_URL');

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get recent transactions
     * @param {transactionState} state
     */
    getRecentTransactions(state) {
      return state.recentTransactions;
    },
    /**
     * get all transactions
     * @param {transactionState} state
     */
    getAllTransactions(state) {
      return state.transactions;
    },
    /**
     * get selected transaction
     * @param {transactionState} state
     */
    getSelectedTransaction(state) {
      return state.selectedTransaction;
    },
    /**
     * get filters
     * @param {transactionState} state
     */
    getFilters(state) {
      return state.filters;
    }
  },
  mutations: {
    /**
     * set transactions
     * @param {transactionState} state
     * @param {transactionState['transactions']} payload
     */
    setTransactions(state, payload) {
      if (payload.action == 'create') {
        state.transactions = payload;
      } else {
        state.transactions.total = payload.total;
        state.transactions.data.push(...payload.data);
      }
    },
    /**
     * set recent transactions
     * @param {transactionState} state
     * @param {transactionState['recentTransactions']} payload
     */
    setRecentTransactions(state, payload) {
      state.recentTransactions = payload;
    },
    /**
     * set selected transactions
     * @param {transactionState} state
     * @param {transactionState['transactions']} payload
     */
    setSelectedTransaction(state, payload) {
      state.selectedTransaction = payload;
    },
    /**
     * update transaction filters
     * @param {transactionState} state
     * @param {transactionState['filters']} payload
     */
    updateFilters(state, payload) {
      state.filters = payload;
    }
  },
  actions: {
    /**
     * get recent transactions
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} param1
     * @param {string} param1.accountId
     * @param {number} param1.limit
     * @param {number} param1.offset
     * @returns {Promise<transactionState['transactions']>}
     */
    fetchAllTransactions(
      { commit },
      {
        limit,
        offset,
        accountId,
        contactId,
        cardId,
        startDate,
        endDate,
        minAmount,
        maxAmount,
        txnType,
        cardIdFromFilter,
        format,
        query
      }
    ) {
      return new Promise((resolve, reject) => {
        let url = `v1/account/${accountId}/transaction?limit=${limit}&offset=${offset}`;
        if (contactId) {
          url += `&contactId=${contactId}`;
        }
        if (cardId) {
          url += `&cardId=${cardId}`;
        } else if (cardIdFromFilter) {
          url += `&cardId=${cardIdFromFilter}`;
        }
        if (startDate) {
          url += `&startDate=${startDate}`;
        }
        if (endDate) {
          url += `&endDate=${endDate}`;
        }
        if (minAmount && minAmount !== '0.00' && minAmount != '0') {
          url += `&minAmount=${minAmount}`;
        }
        if (maxAmount && maxAmount !== '0.00' && maxAmount != '0') {
          url += `&maxAmount=${maxAmount}`;
        }
        if (txnType === 'card transactions') {
          url += '&transferType=card';
        } else if(txnType){
          url += `&txnType=${txnType}`;
        }
        if (format) {
          url += `&export=${format}`;
        }
        if (query) {
          url += `&query=${query}`;
        }
        http(url, {
          method: 'GET',
          responseType: format === 'pdf' ? 'blob' : 'json'
        })
          .then(({ data }) => {
            if (format !== 'pdf') {
              let action = offset === 0 ? 'create' : 'update';
              commit('setTransactions', {
                action,
                ...data
              });
            }
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * get recent transactions
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} param1
     * @param {string} param1.accountId
     * @param {number} param1.limit
     * @param {number} param1.offset
     * @returns {Promise<transactionState['transactions']>}
     */
    fetchRecentTransactions({ commit }, { accountId }) {
      return new Promise((resolve, reject) => {
        let url = `v1/account/${accountId}/transaction?limit=3&offset=0`;

        http
          .get(url)
          .then(({ data }) => {
            commit('setRecentTransactions', data.data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * fetch transactions list
     * @param {*} param0 
     * @param {*} param1 
     * @returns 
     */
    fetchTransaction({ commit }, { accountId, transactionId, format }) {
      return new Promise((resolve, reject) => {
        let url = `v1/account/${accountId}/transaction/${transactionId}`;
        if (format) {
          url += `?export=${format}`;
        }
        http(url, {
          method: 'GET',
          responseType: format === 'pdf' ? 'blob' : 'json'
        })
          .then(({ data }) => {
            if (format !== 'pdf') {
              commit('setSelectedTransaction', data);
            }
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * fetch transactions csv
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} param1
     * @param {string} param1.accountId
     * @param {string} param1.startDate
     * @param {string} param1.endDate
     * @returns 
     */
    getCSV(_, { accountId, startDate, endDate, accessToken }) {
      const filters = { accountId }
      if (startDate) {
        filters.startTime = startDate
      }
      if (endDate) {
        filters.endTime = endDate
      }
      return new Promise((resolve, reject) => {
        axios({
          url: `${multikrdApi}v2/solid-reports/transactions`,
          method: 'post',
          responseType: 'application/json',
          data: filters,
          headers: {
            Authorization: accessToken
          }
        })
          .then(({ data }) => {

            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    }
  }
};
