/**
 * @typedef {Object} accountState
 * @property {Array.<account>} accounts
 * @property {Array.<account>} groupedAccounts
 * @property {account | null} selectedAccount
 * @property {createAccountData | null} createAccountData
 * @property {statement} statements
 * @property {string | null} addAccountBackPage
 */
export default {
  /**
   * @typedef {Object} account
   * @property {string} id
   * @property {string} businessId
   * @property {string} accountNumber
   * @property {string} routingNumber
   * @property {string} label
   * @property {string} availableBalance
   * @property {string} status
   * @property {string} createdAt
   * @property {string} modifiedAt
   * @property {string} fees
   * @property {string} interest
   * @property {Object} sponserBank
   */
  accounts: [],
  groupedAccounts: [],
  selectedAccount: null,
  /**
   * @typedef {Object} createAccountData
   * @property {string} selectedBusinessId
   */
  createAccountData: null,
  /**
   * @typedef {Object} statement
   * @property {number} total
   * @property {Array.<statementData>} data
   *
   * @typedef {Object} statementData
   * @property {number} month
   * @property {number} year
   */
  statements: {
    total: 0,
    data: []
  },
  /**
   * add account back page url - redirect to this page after account created
   */
  addAccountBackPage: null,
  selectedAccountType: 'Cash'
};
