/**
 * @typedef {Object} cardState
 * @property {createCardData} createCardData
 * @property {card} selectedCard
 * @property {Array<card>} cards
 * @property {replaceCardData} replaceCardData
 */

/**
 * @typedef {Object} card
 * @property {string} id
 * @property {string} accountId
 * @property {string} businessId
 * @property {string} programId
 * @property {Object} cardHolder
 * @property {string} cardHolder.id
 * @property {string} cardHolder.personId
 * @property {address} cardHolder.billingAddress
 * @property {string} cardType
 * @property {string} label
 * @property {string} limitAmount
 * @property {string} limitInterval
 * @property {Object} shipping
 * @property {address} shipping.shippingAddress
 * @property {string} shipping.eta
 * @property {string} shipping.deliveryStatus
 * @property {string} currency
 * @property {string} expiryMonth
 * @property {string} expiryYear
 * @property {string} last4
 * @property {string} cardStatus
 */
export default {
  /**
   * @typedef {Object} createCardData
   * @property {string} label
   * @property {string} cardType
   * @property {string} limitInterval
   * @property {string} limitAmount
   * @property {shipping} shipping
   * @property {address} billingAddress
   *
   * @typedef {Object} shipping
   * @property {address} shippingAddress
   *
   * @typedef {Object} address
   * @property {string} line1
   * @property {string} line2
   * @property {string} city
   * @property {string} admin_area
   * @property {string} country
   * @property {string} postalCode
   */

  /**
   * @type {createCardData}
   */
  createCardData: null,
  /**
   * @type {card}
   */
  selectedCard: null,
  /**
   * @type {Array.<card>}
   */
  cards: {
    data: [],
    total: 0
  },
  /**
   * @typedef {Object} replaceCardData
   * @property {string} reason
   */
  replaceCardData: null,
  sentCards: {
    data: [],
    total: 0
  }
};
