/**
 * @typedef {Object} contactState
 * @property {Array.<contact>} contacts
 * @property {contact & {selectedAccountType: String}} createContactData
 * @property {contact} selectedContact
 * @property {Array.<contact>} selfContacts
 */
export default {
  /**
   * @typedef {Object} contact
   * @property {string} id
   * @property {string} accountId
   * @property {string} firstName
   * @property {string} middleName
   * @property {string} lastName
   * @property {string} phone
   * @property {string} createdAt
   * @property {string} modifiedAt
   * @property {string} status
   * @property {contactBankDetails} bankAccount
   * @property {contactBankDetails} ach
   *
   * @typedef {Object} contactBankDetails
   * @property {string} accountNumber
   * @property {string} routingNumber
   */
  contacts: {
    data: [],
    total: 0
  },
  /**
   * @type {contact}
   */
  createContactData: null,
  /**
   * @type {contact}
   */
  selectedContact: null,
  /**
   * self contacts
   * @type {Array.<contact>}
   */
  selfContacts: [],
  /**
   * @typedef {Object} cards
   * @property {number} total
   * @property {Array.<cardsData>} data
   */
  selfCards: {
    data: [],
    total: 0
  }
};
