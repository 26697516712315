import Transaction from './index.vue';

const routes = [
  {
    path: '/transaction',
    name: 'Transaction',
    component: Transaction,
    children: [
      {
        path: 'list',
        name: 'TransactionList',
        component: () => import('./views/TransactionList.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'details',
        name: 'TransactionDetails',
        component: () => import('./views/TransactionDetails.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'filter',
        name: 'TransactionFilter',
        component: () => import('./views/TransactionFilter.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'export',
        name: 'TransactionExport',
        component: () => import('./views/TransactionExport.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      }
    ]
  }
];

export default routes;
