<template>
  <div>
    <el-drawer
      ref="drawer"
      :show-close="false"
      :size="size"
      :visible.sync="show"
      :with-header="false"
      :before-close="handleClose"
      @open="setDefaultComponent"
      :destroy-on-close="true"
      direction="rtl">
      <div v-if="currentIndex >= 0">
        <DrawerLoader v-show="showDrawerLoader" />
        <component
          v-mounted="afterChildInserted"
          v-show="!showDrawerLoader"
          @goTo="goTo(...arguments)"
          @refresh="refresh"
          @back="goBack"
          @close="close(...arguments)"
          :is="drawerComponent.component"
          v-on="drawerComponent.methods"
          v-bind="drawerComponent.data" />
      </div>
    </el-drawer>
  </div>
</template>
<script>

import DrawerLoader from './DrawerLoader.vue';
export default {
  data() {
    return {
      showDrawerLoader: false,
      currentIndex: -1,
      stack: [],
      size: '100%',
    }
  },
  components: {
    DrawerLoader
  },
  props: {
    components: {
      type: Array,
      default: function() {
        return []
      }
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    drawerComponent:function() {
      return this.components[this.currentIndex];
    }
  },
  created() {
    this.setDefaultComponent();
  },
  methods: {
    /**
     * go to particular tab
     * @param {string} name component name
     * @param {'push' | 'replace'} type
     */
    goTo(name, action = 'push') {
      const componentIndex = this.components.findIndex(c => c.name === name);
      if(componentIndex !== -1) {
        this.currentIndex = componentIndex;
        if(action === 'push') {
          this.stack.push(this.components[componentIndex]);
        } else {
          this.stack.pop();
          this.stack.push(this.components[componentIndex]);
        }
      } else {
        throw Error('Unknown component')
      }
    },

    /**
     * go back
     */
    goBack() {
      if(this.currentIndex > 0) {
        this.stack.pop();
        const previousPage = this.stack[this.stack.length - 1];
        const componentIndex = this.components.findIndex(c => c.name === previousPage.name);
        this.currentIndex = componentIndex;
      }
    },
    /**
     * close
     */
    close() {
      this.$refs.drawer.closeDrawer();
    },
    /**
     * handle close action
     */
    handleClose() {
      this.$emit('update:show', false);
      this.stack = [];
      this.setDefaultComponent();
      setTimeout(() => {
        this.currentIndex = -1;
      }, 0)
      this.$emit('closed');
    },
    /**
     * set default component
     */
    setDefaultComponent() {
      const defaultComponentIndex = this.components.findIndex(c => c.default === true);
      this.currentIndex = defaultComponentIndex;
      if(defaultComponentIndex !== -1) {
        this.stack.push(this.components[defaultComponentIndex]);
      }
    },
    afterChildInserted() {
      this.showDrawerLoader = false;
    },
    refresh() {
      this.$emit('refresh');
    }
  },
  directives: {
    mounted: {
      inserted: function(_, binding) {
        binding.value();
      }
    }
  },
  watch: {
    show: function(newVal) {
      if(newVal === true) {
        this.setDefaultComponent();
      }
    },
    currentIndex: function() {
      this.showDrawerLoader = true;
    }
  }
}
</script>