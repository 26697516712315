/**
 * @typedef {Object} kybState
 * @property {bussinessDetails} bussinessDetails
 * @property {string} bussinessDetailsFetchedStatus
 * @property {selectedBusinessType} selectedBusinessType
 * @property {kyb | null} kybDetails
 * @property {agreementUrlAndStatus | null} agreementUrlAndStatus
 * @property {Array.<owners>} owners
 * @property {owners} createPageOwnerDetails
 */
export default {
  /**
   * @typedef {Object} bussinessDetail
   * @property {string} id
   * @property {string} legalName
   * @property {string} formationDate
   * @property {string} entityType
   * @property {string} dba
   * @property {string} email
   * @property {string} idNumber
   * @property {string} idType
   * @property {string} phone
   * @property {string} formationDate
   * @property {string} website
   * @property {string} about
   * @property {string} industry
   * @property {address} address
   * @property {kyb} kyb
   * @property {string} disclosureStatus
   *
   * @typedef {Object} address
   * @property {string} addressType
   * @property {string} line1
   * @property {string} line2
   * @property {string} city
   * @property {string} state
   * @property {string} country
   * @property {string} postalCode
   *
   * @typedef {Object} kyb
   * @property {string} id
   * @property {string} businessId
   * @property {string} status
   * @property {string} reviewMessage
   * @property {kybResults} results
   *
   * @typedef {Object} kybResults
   * @property {string} risk
   * @property {string} fraud
   */

  /**
   * @typedef {Array.<bussinessDetail>} bussinessDetails
   */
  bussinessDetails: [],
  bussinessDetailsFetchedStatus: 'notFetched',
  /** @typedef {bussinessDetail | null} selectedBusinessType */
  selectedBusinessType: null,
  /** @type {kyb | null} */
  kybDetails: null,
  /**
   * @typedef {Object} agreementUrlAndStatus
   * @property {string} url
   * @property {string} status
   * @property {string|null} signedAt
   */
  agreementUrlAndStatus: null,
  /**
   * @typedef {Object} owners
   * @property {string} id
   * @property {string} businessId
   * @property {boolean} isControlPerson
   * @property {number} ownership
   * @property {string} title
   * @property {import('@m/user/store/state').userState['personDetails']} person
   */
  owners: [],
  createPageOwnerDetails: null,
  /**
   * @typedef {Object} nacisCodeData
   * @property {string} id
   * @property {string} businessId
   * @property {boolean} isControlPerson
   * @property {number} ownership
   * @property {string} title
   * @property {import('@m/user/store/state').userState['personDetails']} person
   */
  nacisCodeData: [],
  projectionData: null,
  ownershipDisclosure: null,
  createPageDocumentDetails: null,
  /**
 * @typedef {Object} documents
 * @property {string} id
 * @property {string} docType
 * @property {string} name
 * @property {string} description
 * @property {string} documentNumber
 * @property {string} issuingCountry
 * @property {string} issuingState
 * @property {string} expirationDate
 */
  documents: [],
};
