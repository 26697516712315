import Kyc from './index.vue';

const routes = [
  {
    path: '/kyc',
    name: 'Kyc',
    component: Kyc,
    children: [
      {
        path: 'accountOnboarding',
        name: 'AccountOnboarding',
        component: () =>
          import(
            /* webpackChunkName: "kyc-personal" */ './views/AccountOnboarding.vue'
          ),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true,
          layout: 'ao'
        }
      },
      {
        path: 'personDetails',
        name: 'PersonDetails',
        component: () =>
          import(
            /* webpackChunkName: "kyc-personal" */ './views/PersonDetails.vue'
          ),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true,
          layout: 'ao'
        }
      },
      {
        path: 'personAddress',
        name: 'PersonAddress',
        component: () =>
          import(
            /* webpackChunkName: "kyc-personal-address" */ './views/PersonAddress.vue'
          ),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true,
          layout: 'ao'
        }
      },
      {
        path: 'verify',
        name: 'Persona',
        component: () =>
          import(
            /* webpackChunkName: "kyc-verify" */ './views/Persona.vue'
          ),
        meta: {
          requiresAuth: true,
          layout: 'ao',
          preventBrowserBack: true
        }
      },
      {
        path: 'kycStatus',
        name: 'KycStatus',
        component: () =>
          import(/* webpackChunkName: "kyc-status" */ './views/KycStatus.vue'),
        meta: {
          layout: 'ao',
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'accountType',
        name: 'accountType',
        component: () =>
          import(
            /* webpackChunkName: "kyc-account-type" */ './views/AccountTypeSelection.vue'
          ),
        meta: {
          layout: 'ao',
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'idv-callback',
        name: 'IdvCallback',
        component: () =>
          import(/* webpackChunkName: "idv-callback" */ './views/IdvCallback.vue'),
        meta: {
          layout: 'ao',
          requiresAuth: false,
          preventBrowserBack: false
        }
      },
    ]
  }
];

export default routes;
